var listPlugins = {
  plugins: [
    "removeDoctype",
    "removeXMLProcInst",
    "removeComments",
    "removeMetadata",
    "removeXMLNS",
    "removeEditorsNSData",
    "cleanupAttrs",
    "inlineStyles",
    "minifyStyles",
    "convertStyleToAttrs",
    "cleanupIDs",
    "prefixIds",
    "removeRasterImages",
    "removeUselessDefs",
    "cleanupNumericValues",
    "cleanupListOfValues",
    "convertColors",
    "removeUnknownsAndDefaults",
    "removeNonInheritableGroupAttrs",
    "removeUselessStrokeAndFill",
    "removeViewBox",
    "cleanupEnableBackground",
    "removeHiddenElems",
    "removeEmptyText",
    "convertShapeToPath",
    "convertEllipseToCircle",
    "moveElemsAttrsToGroup",
    "moveGroupAttrsToElems",
    "collapseGroups",
    "convertPathData",
    "convertTransform",
    "removeEmptyAttrs",
    "removeEmptyContainers",
    "mergePaths",
    "removeUnusedNS",
    "sortAttrs",
    "sortDefsChildren",
    "removeTitle",
    "removeDesc",
    "removeDimensions",
    "removeAttrs",
    "removeAttributesBySelector",
    "removeElementsByAttr",
    "addClassesToSVGElement",
    "removeStyleElement",
    "removeScriptElement",
    "addAttributesToSVGElement",
    "removeOffCanvasPaths",
    "reusePaths",
  ],
};

module.exports.listPlugins = listPlugins;
